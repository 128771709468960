import { hydrateRoot } from 'react-dom/client'
import { RemixBrowser } from '@remix-run/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from '~/config'

if (config?.sentry?.active) {
  Sentry.init({
    dsn: config?.sentry?.dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

hydrateRoot(document, <RemixBrowser />)
